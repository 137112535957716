import React, { createContext, useContext, useReducer } from 'react';
import { messageReducer, initialState } from '../reducers/MessageReducer';
import { MessageContextType } from '../types/MessageTypes';
import { CSMCommandMessage, CSMMisoMessage, RefkitMessage, ThimphuMessage } from '../../types/types';
import { CSM_PROTOCOL_LIFECYCLE_EVENT_STATE } from '../../components/serial/csm';

export const MessageContext = createContext<MessageContextType | undefined>(undefined);

export function useMessageContext(): MessageContextType {
  const context = useContext(MessageContext);
  if (!context) {
    throw new Error('useMessageContext must be used within a MessageProvider');
  }
  return context;
}

export function MessageProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(messageReducer, initialState);

  // THIMPHU
  const addThimphuMessage = (message: ThimphuMessage) => {
    dispatch({ type: 'ADD_THIMPHU_MESSAGE', payload: message });
  };
  const consumeThimphuMessage = (messageId: string) => {
    dispatch({ type: 'CONSUME_THIMPHU_MESSAGE', payload: messageId });
  };
  const setThimphuPort = (port: SerialPort | undefined) => {
    dispatch({ type: 'SET_THIMPHU_PORT', payload: port });
  };
  const setThimphuIsConnected = (isConnected: boolean) => {
    dispatch({ type: 'SET_THIMPHU_IS_CONNECTED', payload: isConnected });
  };
  const clearThimphuMessages = () => {
    dispatch({ type: 'CLEAR_THIMPHU_MESSAGES' });
  };

  // REFKIT
  const addRefkitMessage = (message: RefkitMessage) => {
    dispatch({ type: 'ADD_REFKIT_MESSAGE', payload: message });
  };
  const consumeRefkitMessage = (messageId: string) => {
    dispatch({ type: 'CONSUME_REFKIT_MESSAGE', payload: messageId });
  };
  const setRefkitPort = (port: SerialPort | undefined) => {
    dispatch({ type: 'SET_REFKIT_PORT', payload: port });
  };
  const setRefkitIsConnected = (isConnected: boolean) => {
    dispatch({ type: 'SET_REFKIT_IS_CONNECTED', payload: isConnected });
  };
  const clearRefkitMessages = () => {
    dispatch({ type: 'CLEAR_REFKIT_MESSAGES' });
  };

  // CSM
  const addCSMMessage = (message: CSMMisoMessage) => {
    dispatch({ type: 'ADD_CSM_MESSAGE', payload: message });
    // push into broker ?
  };
  const consumeCSMMessage = (messageId: string) => {
    dispatch({ type: 'CONSUME_CSM_MESSAGE', payload: messageId });
  };
  const setCSMBleDevice = (device: BluetoothDevice | undefined) => {
    dispatch({ type: 'SET_CSM_BLE_DEVICE', payload: device });
  };
  const setCSMIsConnected = (isConnected: boolean) => {
    dispatch({ type: 'SET_CSM_IS_CONNECTED', payload: isConnected });
  };
  const setCSMstate = (csmState: CSM_PROTOCOL_LIFECYCLE_EVENT_STATE) => {
    dispatch({ type: 'SET_CSM_STATE', payload: csmState });
  };
  const resetCSMstate = () => {
    dispatch({ type: 'RESET_CSM_STATE' });
  };
  const clearCSMMessages = () => {
    dispatch({ type: 'CLEAR_CSM_MESSAGES' });
  };
  const addCSMCommand = (message: CSMCommandMessage) => {
    dispatch({ type: 'ADD_CSM_COMMAND', payload: message });
  };
  const consumeCSMCommand = (messageId: string) => {
    dispatch({ type: 'CONSUME_CSM_COMMAND', payload: messageId });
  };
  const clearCSMCommands = () => {
    dispatch({ type: 'CLEAR_CSM_COMMANDS' });
  };
  const saveHihValue = (humidity: number, temperature: number) => {
    dispatch({ type: 'STORE_HIH_VALUE', payload: { humidity: humidity, temperature: temperature } });
  };

  const contextValue: MessageContextType = {
    // THIMPHU
    thimphuMessages: state.thimphuMessages,
    thimphuPort: state.thimphuPort,
    thimphuIsConnected: state.thimphuIsConnected,

    addThimphuMessage,
    consumeThimphuMessage,
    setThimphuPort,
    setThimphuIsConnected,
    clearThimphuMessages,

    // REFKIT
    refkitMessages: state.refkitMessages,
    refkitPort: state.refkitPort,
    refkitIsConnected: state.refkitIsConnected,

    addRefkitMessage,
    consumeRefkitMessage,
    setRefkitPort,
    setRefkitIsConnected,
    clearRefkitMessages,

    // CSM
    csmMessages: state.csmMessages,
    csmCommands: state.csmCommands,
    csmBleDevice: state.csmBleDevice,
    csmIsConnected: state.csmIsConnected,
    csmState: state.csmState,

    addCSMMessage,
    consumeCSMMessage,
    clearCSMMessages,
    addCSMCommand,
    consumeCSMCommand,
    clearCSMCommands,
    setCSMBleDevice,
    setCSMIsConnected,
    setCSMstate,
    resetCSMstate,

    // HIH
    hihValues: state.hihValues,
    saveHihValue,
  };

  return <MessageContext.Provider value={contextValue}>{children}</MessageContext.Provider>;
}
