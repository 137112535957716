import { useState, useEffect } from 'react';

export type ColorTuple = [number, number, number];

export const ARYBALLE_COLOR_PINK = '#FD4282';
export const ARYBALLE_COLOR_YELLOW = '#FFC500';
export const ARYBALLE_COLOR_CYAN = '#4DE5DB';
export const ARYBALLE_COLOR_BLUE = '#1193F5';
export const ARYBALLE_COLOR_PURPLE = '#7451F1';

export const ARYBALLE_COLOR_WHITE = '#FFFFFF';
export const ARYBALLE_COLOR_GRAY = '#9AA3B4';
export const ARYBALLE_COLOR_GRAY_DARK = '#464646';
export const ARYBALLE_COLOR_BLUE_DARK = '#070623';

export const colorPalette = {
  blueDarkGradient: 'radial-gradient(164.14% 76.29% at 49.87% 76.29%, rgba(0, 0, 39, 0.92) 0%, #000014 100%)',
  blue: '#02113C',
  grey: '#08213F',
  cyan: '#66E5DC',
  blueLight: '#1890FF',
};

const KNOWN_POR1_POR2_POR3 = [24, 1, 18, 19, 21, 55, 27, 28, 63, 30, 62, 66, 25, 22, 20, 23, 29, 26, 54, 64, 65];

export const PEPTIDE_COLOR_MAP_VDW: Record<number, string> = {
  // POR1
  19: '#A5EB63',
  20: '#E08B4C',
  22: '#E0535F',
  23: '#FA2655',
  25: '#317ADE',
  26: '#4BFA97',
  27: '#62E051',
  28: '#FAB643',
  29: '#F57656',
  30: '#C232FA',
  55: '#6A31DE',
  62: '#424BF5',
  63: '#FAC44B',
  66: '#F5E322',

  0: '#BDBDBD',
  1: '#969696',
  10: '#525252',

  // POR2
  21: '#4BD9F9',
  18: '#FFBF75',
  65: '#91CD43',
  54: '#EA279C',
  24: '#EA8383',
  64: '#BD00FF',

  // POR4
  34: '#FF00FF',
  36: '#00FFFF',
  105: '#FF9900',
  106: '#228B22',
  224: '#FF0066',
  234: '#1811a8',
  244: '#FF3300',
  254: '#66FF33',
};
export const DEFAULT_COLOR_FOR_UNKNOWN_PEPTIDE = '#722ED1';

export const NON_STANDARD_PEPTIDE_COLOR_MAP: string[] = [
  '#ff7f0e', // orange
  '#2ca02c', // vert
  '#8c56ff', // violet
  '#17beff', // bleu ciel
  '#d62728', // rouge
  '#8c564b', // marron
  '#bcbd22', // vert pomme
  '#17becf', // bleu
  '#ff7fff', // rose fushia
  '#083B8B', // bleu
  '#d627ff', // violet fushia
  '#BD8167', // marron clair
  '#0072C0', // bleu foncer
  '#F46F51', // saumon
  '#7f7fff', // violet
  '#1fffb4', // vert
  '#FFD12C', // jaune
  '#7fff7f', // vert
  '#5A1F1F', // bordeau
  '#17ffcf', // vert  bleu
  '#3FA190', // vert sapin
];

export const colorTupleToRGBA = (color: ColorTuple, a: number): string => {
  return `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${a})`;
};

export const colorHexToTuple = (color: string): ColorTuple => {
  return [parseInt(color.slice(1, 3), 16), parseInt(color.slice(3, 5), 16), parseInt(color.slice(5, 7), 16)];
};
export const colorHexToRGBA = (color: string, a: number): string => {
  return colorTupleToRGBA(colorHexToTuple(color), a);
};

export const spotsgrid2dCoordinatesTo1dIndex = (row: number, col: number): number => {
  return (15 - row) * 4 + col;
};

export const spotsgrid1dIndexTo2dCoordinates = (index: number): [number, number] => {
  const row = 15 - Math.floor(index / 4);
  const col = index % 4;
  return [row, col];
};

export const rowIdxToLetter = (rowIdx: number): string => {
  return String.fromCharCode(65 + rowIdx);
};

export const useMediaQuery = () => {
  const mediaMatch = window.matchMedia('(max-width: 768px)');
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = (e: any) => {
      setMatches(e.matches);
    };
    mediaMatch.addEventListener('change', handler);
    return () => mediaMatch.removeEventListener('change', handler);
  }, []);
  return matches;
};

export const isBleApp = () => {
  // return window.location.hostname.includes('m.webapp');
  return true;
};

export const shouldTrimSpotsgrid = (spotsgridInt: number[]): boolean => {
  let knownSensorsExtended = KNOWN_POR1_POR2_POR3.map((x) => extendSensor(x));
  let shouldTrim = true;
  for (let spot of spotsgridInt) {
    if (!knownSensorsExtended.includes(Math.abs(spot))) {
      shouldTrim = false;
    }
  }
  return shouldTrim;
};

export const trimSensor = (sensorInt: number): number => {
  if (Math.abs(sensorInt) <= 10) {
    return sensorInt;
  } else {
    return Math.trunc(sensorInt / 10);
  }
};

// extend trimmed peptide code, adding the classic chemistry type "4" at the end
export const extendSensor = (sensorInt: number): number => {
  if (Math.abs(sensorInt) <= 10) {
    return sensorInt;
  } else if (sensorInt > 0) {
    return sensorInt * 10 + 4;
  } else {
    return sensorInt * 10 - 4;
  }
};

export const shouldExtendSpotsgrid = (spotsgridInt: number[]): boolean => {
  let knownSensors = KNOWN_POR1_POR2_POR3;
  let shouldExtend = true;
  for (let spot of spotsgridInt) {
    if (!knownSensors.includes(Math.abs(spot))) {
      shouldExtend = false;
    }
  }
  return shouldExtend;
};
