import { CSM_PROTOCOL_LIFECYCLE_EVENT_STATE } from '../../components/serial/csm';
import { Action, State } from '../types/MessageTypes';

const MAX_MESSAGE_QUEUE_SIZE = 100;

export const initialState: State = {
  thimphuMessages: [],
  thimphuIsConnected: false,

  refkitMessages: [],
  refkitIsConnected: false,

  csmMessages: [],
  csmCommands: [],
  csmIsConnected: false,
  csmState: CSM_PROTOCOL_LIFECYCLE_EVENT_STATE.Initializing,
  hihValues: { humidity: 2, temperature: 3 },
};

export function messageReducer(state: State, action: Action): State {
  switch (action.type) {
    // THIMPHU
    case 'ADD_THIMPHU_MESSAGE':
      let newThimphuMessages = [...state.thimphuMessages, action.payload];
      if (newThimphuMessages.length > MAX_MESSAGE_QUEUE_SIZE) {
        newThimphuMessages.shift();
      }
      return {
        ...state,
        thimphuMessages: newThimphuMessages,
      };
    case 'CONSUME_THIMPHU_MESSAGE':
      const messageId = action.payload;
      const updatedMessages = state.thimphuMessages.filter((message) => message.id !== messageId);
      return {
        ...state,
        thimphuMessages: updatedMessages,
      };
    case 'SET_THIMPHU_PORT':
      return {
        ...state,
        thimphuPort: action.payload,
      };
    case 'SET_THIMPHU_IS_CONNECTED':
      return {
        ...state,
        thimphuIsConnected: action.payload,
      };
    case 'CLEAR_THIMPHU_MESSAGES':
      return {
        ...state,
        thimphuMessages: [],
      };

    // REFKIT
    case 'ADD_REFKIT_MESSAGE':
      let newRefkitMessages = [...state.refkitMessages, action.payload];
      if (newRefkitMessages.length > MAX_MESSAGE_QUEUE_SIZE) {
        newRefkitMessages.shift();
      }
      return {
        ...state,
        refkitMessages: newRefkitMessages,
      };
    case 'CONSUME_REFKIT_MESSAGE':
      const refkitMessageId = action.payload;
      const updatedRefkitMessages = state.refkitMessages.filter((message) => message.id !== refkitMessageId);
      return {
        ...state,
        refkitMessages: updatedRefkitMessages,
      };
    case 'SET_REFKIT_PORT':
      return {
        ...state,
        refkitPort: action.payload,
      };
    case 'SET_REFKIT_IS_CONNECTED':
      return {
        ...state,
        refkitIsConnected: action.payload,
      };
    case 'CLEAR_REFKIT_MESSAGES':
      return {
        ...state,
        refkitMessages: [],
      };

    // CSM
    case 'ADD_CSM_MESSAGE':
      let newCsmMessages = [...state.csmMessages, action.payload];
      if (newCsmMessages.length > MAX_MESSAGE_QUEUE_SIZE) {
        newCsmMessages.shift();
      }
      return {
        ...state,
        csmMessages: newCsmMessages,
      };
    case 'CONSUME_CSM_MESSAGE':
      const csmMessageId = action.payload;
      const updatedCSMMessages = state.csmMessages.filter((message) => message.id !== csmMessageId);
      return {
        ...state,
        csmMessages: updatedCSMMessages,
      };
    case 'SET_CSM_BLE_DEVICE':
      return {
        ...state,
        csmBleDevice: action.payload,
      };
    case 'SET_CSM_IS_CONNECTED':
      return {
        ...state,
        csmIsConnected: action.payload,
      };
    case 'RESET_CSM_STATE':
      return {
        ...state,
        csmState: CSM_PROTOCOL_LIFECYCLE_EVENT_STATE.Initializing,
      };
    case 'SET_CSM_STATE':
      // persist errors if any
      switch (state.csmState) {
        case CSM_PROTOCOL_LIFECYCLE_EVENT_STATE.CalibrationError:
        case CSM_PROTOCOL_LIFECYCLE_EVENT_STATE.InitializationError:
          return state;
        default:
          return {
            ...state,
            csmState: action.payload,
          };
      }
    case 'CLEAR_CSM_MESSAGES':
      return {
        ...state,
        csmMessages: [],
      };
    case 'ADD_CSM_COMMAND':
      let newCsmCommands = [...state.csmCommands, action.payload];
      if (newCsmCommands.length > MAX_MESSAGE_QUEUE_SIZE) {
        newCsmCommands.shift();
      }
      return {
        ...state,
        csmCommands: newCsmCommands,
      };
    case 'CONSUME_CSM_COMMAND':
      const csmCommandId = action.payload;
      const updatedCSMCommands = state.csmCommands.filter((message) => message.id !== csmCommandId);
      return {
        ...state,
        csmCommands: updatedCSMCommands,
      };
    case 'CLEAR_CSM_COMMANDS':
      return {
        ...state,
        csmCommands: [],
      };

    case 'STORE_HIH_VALUE':
      return {
        ...state,
        hihValues: action.payload,
      };

    default:
      return state;
  }
}
