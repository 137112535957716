import { FC, useEffect, useRef, useState } from 'react';
import { ConnectionIndicator } from '../../components/widgets/Connection/connectionIndicator';
import {
  csmMosiWriteToCharacteristic,
  csmPumpWriteToCharacteristic,
  getBleDeviceCandidates,
  getBleService,
  getHeartbeatCharacteristic,
  getMisoCharacteristic,
  getMosiCharacteristic,
  getPumpCharacteristic,
  navigatorSupportsWebBle,
} from '../../services/ble/ble';
import { DEFAULT_DEVICE_DETECTION_INTERVAL } from '../../components/serial/constants';
import {
  ARDUINO_CUT_MSG_EVENT_STATE,
  ARDUINO_EVENT_TYPE,
  CSM_PROTOCOL_COMMAND_TYPE,
  CSM_PROTOCOL_EVENT_TYPE,
  CSM_PROTOCOL_LIFECYCLE_EVENT_STATE,
  csmMosiGetBiosensorSignalMap,
  csmMosiGetVersions,
  csmMosiStopSampling,
  decodeArduinoMsgCutEvent,
  decodeMisoFrame,
  encodeMosiCommand,
  parseBiosensorSignalMapResponse,
  parseBiosensorsSignalEvent,
  parseEventPayload,
  parseResponsePayload,
  PERIPHERAL_ADDRESS,
} from '../../components/serial/csm';
import { v4 as uuidv4 } from 'uuid';
import { saveSpotsGrid1D } from '../../services/cache/localStorage';
import { Mutex, withTimeout, MutexInterface } from 'async-mutex';
import { message as antdMessage } from 'antd';
import { useMessageContext } from '../context/MessageContext';

const SPOTGRIDPOR3 = [];

export const CSMBleMessageDispatcher: FC<{
  minimized: boolean;
}> = ({ minimized }) => {
  // Important! Two notification characteristics on a single device causes unknown GATTT error on Androind
  // const [ heartbeatCharacteristic, setHeartbeatCharacteristic ] = useState<BluetoothRemoteGATTCharacteristic | null>(null)
  const [misoCharacteristic, setMisoCharacteristic] = useState<BluetoothRemoteGATTCharacteristic | null>(null);
  const [mosiCharacteristic, setMosiCharacteristic] = useState<BluetoothRemoteGATTCharacteristic | null>(null);
  const [pumpCharacteristic, setPumpCharacteristic] = useState<BluetoothRemoteGATTCharacteristic | null>(null);

  const sendCommandMutexRef = useRef<MutexInterface>(withTimeout(new Mutex(), 1000));

  // for split messages
  const receivingMessagePackets = useRef<boolean>(false);
  const receivedMsgId = useRef<number>(-1);
  const frameArrayRef = useRef<Uint8Array>(new Uint8Array());
  const nbPacketsReceived = useRef<number>(0);

  const { csmMessages, csmCommands, csmBleDevice, csmIsConnected, csmState, addCSMMessage, addCSMCommand, setCSMBleDevice, setCSMIsConnected, setCSMstate, resetCSMstate, consumeCSMCommand, clearCSMCommands, saveHihValue } = useMessageContext();

  const resetCutMsgRefs = () => {
    receivingMessagePackets.current = false;
    receivedMsgId.current = -1;
    frameArrayRef.current = new Uint8Array();
    nbPacketsReceived.current = 0;
  };

  const handleHeartbeatCharacteristicValueChanged = (event: Event) => {
    if (event.target === null) {
      return;
    }
    let v = (event.target as BluetoothRemoteGATTCharacteristic).value;
    if (!v) {
      return;
    }
    let d = v.getUint16(0, true);
    console.debug('heartbeat characteristic value changed: nMsg', d);
  };

  const handleMisoCharacteristicValueChanged = (event: Event) => {
    if (event.target === null) {
      return;
    }
    let v = (event.target as BluetoothRemoteGATTCharacteristic).value;
    if (!v) {
      return;
    }

    let frameArray = new Uint8Array(v.buffer);
    if (frameArray.length < 2) {
      console.log('received array of size ', frameArray.length, frameArray);
      return;
    }
    // console.log(frameArray.byteLength)

    // handle HIH cases
    // Important! Two notification characteristics on a single device causes unknown GATTT error on Android
    // That's why HIH is being sent through MISOcharacteristic
    if (frameArray[0] == PERIPHERAL_ADDRESS.HIH) {
      //console.log("value is :", v)
      //console.log("buffer is :", v.buffer)
      const [temperature, humidity] = decodeFloatsFromHIHbuffer(v.buffer);
      //console.log("Temperature:", temperature);
      //console.log("Humidity:", humidity);
      saveHihValue(humidity, temperature);
      return;
    }

    // prepare/stop receiving big messages split into several packets by Arduino
    if (frameArray[0] == PERIPHERAL_ADDRESS.ARDUINO && frameArray[1] == ARDUINO_EVENT_TYPE.CutMsg) {
      // console.debug('receiving cut msg event', frameArray);
      let decodedEvent = decodeArduinoMsgCutEvent(frameArray);
      if (decodedEvent.State === ARDUINO_CUT_MSG_EVENT_STATE.Sending) {
        receivingMessagePackets.current = true;
        receivedMsgId.current = decodedEvent.MsgId;
        // console.debug('start collecting message with ID %d of length %d split in %d packets', decodedEvent.MsgId, decodedEvent.MsgLength, decodedEvent.NbPackets);
        return;
      } else {
        frameArray = frameArrayRef.current;

        // sanity checks
        if (receivedMsgId.current !== decodedEvent.MsgId) {
          console.error('error validating packets collection, msgId mismatch: was collecting id %d and received transfer complete event for id %d', receivedMsgId.current, decodedEvent.MsgId);
          resetCutMsgRefs();
          return;
        }
        if (nbPacketsReceived.current !== decodedEvent.NbPackets) {
          console.error('error validating packets collection, mismatch in nb of packets: expected %d packets, received only %d', decodedEvent.NbPackets, nbPacketsReceived.current);
          resetCutMsgRefs();
          return;
        }

        // message has been  successfully received, reset variables and continue with the rest of the processing
        // console.debug('packets collection complete for message %d', decodedEvent.MsgId);
        resetCutMsgRefs();
      }
    }

    // handle big messages split into several packets by Arduino
    if (receivingMessagePackets.current) {
      // console.debug('receiving packets...');

      // Note : should definitely be OK in terms of performance as merged arrays shouldn't be that big (len < 512 bytes)
      // if improvement needed, can be initialised directly with the right size when a cutMsg event is being received
      // and filled with "set" method
      var mergedArray = new Uint8Array([...frameArrayRef.current, ...frameArray]);
      frameArrayRef.current = mergedArray;
      nbPacketsReceived.current++;
      return;
    }

    // console.log(v.buffer)
    let frame = decodeMisoFrame(frameArray);

    if (frame.LocalCRC !== frame.RemoteCRC) {
      return;
    }

    if (frame.Type < 0x80) {
      // miso responses
      console.log('miso response', frame);
      addCSMMessage({
        id: uuidv4().toString(),
        ts: Date.now(),
        message: frame,
      });
      if (frame.Type === CSM_PROTOCOL_COMMAND_TYPE.GetBiosensorSignalMap) {
        console.log('got biosensor signal map', frame.Payload);
        try {
          console.log(frame.Payload);
          let responsePayload = parseResponsePayload(frame.Payload);
          console.log('responsePayload', responsePayload);
          let spotsgrid1d = parseBiosensorSignalMapResponse(responsePayload.Data);
          //spotsgrid1d.shift();
          // [66, 63, 25, 1, 64, 27, 19, 24, 29, 26, 18, 28, 20, 21, 62, 30, 23, 55, 54, 22, 65]
          console.log('biosensor signal map', spotsgrid1d);
          saveSpotsGrid1D(spotsgrid1d);
        } catch (e: any) {
          console.log('error parsing biosensor signal map response', e.message);
        }
      }
    } else {
      //console.log('frame type is:', frame.Type)
      // miso events
      switch (frame.Type) {
        case CSM_PROTOCOL_EVENT_TYPE.BiosensorsSignalEvent:
          addCSMMessage({
            id: uuidv4().toString(),
            ts: Date.now(),
            message: frame,
          });
          break;
        case CSM_PROTOCOL_EVENT_TYPE.ErrorEvent:
          console.log('csm protocol error: ', frame.Payload);
          break;
        case CSM_PROTOCOL_EVENT_TYPE.HeartbeatEvent:
          let event = parseEventPayload(frame.Payload);
          // console.log('csm protocol heartbeat: ', event.Counter)
          break;
        case CSM_PROTOCOL_EVENT_TYPE.LifeCycleEvent:
          let lcEvent = parseEventPayload(frame.Payload);
          let lcState = lcEvent.Data[0] as CSM_PROTOCOL_LIFECYCLE_EVENT_STATE;
          let lcDataByte = lcEvent.Data[0];
          if (lcDataByte === undefined) {
            break;
          }
          let lcStateString = CSM_PROTOCOL_LIFECYCLE_EVENT_STATE[lcDataByte];
          console.log('csm protocol lifecycle: ', lcEvent, lcStateString);
          setCSMstate(lcState);
          switch (lcState) {
            case CSM_PROTOCOL_LIFECYCLE_EVENT_STATE.Initializing:
            case CSM_PROTOCOL_LIFECYCLE_EVENT_STATE.Calibrating:
              antdMessage.info(`CSM is ${lcStateString}..`);
              break;
            case CSM_PROTOCOL_LIFECYCLE_EVENT_STATE.Ready:
            case CSM_PROTOCOL_LIFECYCLE_EVENT_STATE.Acquiring:
              antdMessage.success(`CSM is ${lcStateString}!`);
              break;
            case CSM_PROTOCOL_LIFECYCLE_EVENT_STATE.CalibrationError:
            case CSM_PROTOCOL_LIFECYCLE_EVENT_STATE.InitializationError:
              antdMessage.error(`CSM LifeCycle ${lcStateString} 😿! You might need to unplug and replug the sensor..`);
              break;
          }
          break;
        default:
          console.log('csm protocol unknown: ', frame.Type, frame.Payload);
      }
    }
    // console.log('miso characteristic value', v, frame, event.timeStamp)
  };

  // Function to decode two floats from an HIH Buffer
  const decodeFloatsFromHIHbuffer = (arrayBuffer: ArrayBuffer): [number, number] => {
    const dataView = new DataView(arrayBuffer);

    // Read the first float (4 bytes) from the ArrayBuffer
    const float1 = dataView.getFloat32(1, true); // true for little-endian
    // Read the second float (next 4 bytes) from the ArrayBuffer
    const float2 = dataView.getFloat32(5, true); // true for little-endian

    return [float1, float2];
  };

  // Important! Two notification characteristics on a single device causes unknown GATTT error on Androind
  // useEffect(() => {
  //     if (heartbeatCharacteristic === null) {
  //         return
  //     }
  //     heartbeatCharacteristic.oncharacteristicvaluechanged = handleHeartbeatCharacteristicValueChanged
  //     heartbeatCharacteristic.startNotifications()
  //     console.log('heartbeat characteristic with value changed handler', heartbeatCharacteristic)
  // }, [heartbeatCharacteristic])

  useEffect(() => {
    console.log('test');
    if (misoCharacteristic === null) {
      return;
    }
    try {
      misoCharacteristic.oncharacteristicvaluechanged = handleMisoCharacteristicValueChanged;
      misoCharacteristic.startNotifications();
      console.log('miso characteristic with value changed handler', misoCharacteristic);
    } catch (e: any) {
      console.log('error starting notifications for miso characteristic', e.message);
    }
  }, [misoCharacteristic]);

  useEffect(() => {
    if (mosiCharacteristic === null) {
      return;
    }
    clearCSMCommands();
    addCSMCommand({
      id: uuidv4().toString(),
      message: {
        CmdType: CSM_PROTOCOL_COMMAND_TYPE.GetBiosensorSignalMap,
      },
    });
    addCSMCommand({
      id: uuidv4().toString(),
      message: {
        CmdType: CSM_PROTOCOL_COMMAND_TYPE.GetVersions,
      },
    });
    resetCSMstate();
    addCSMCommand({
      id: uuidv4().toString(),
      message: {
        CmdType: CSM_PROTOCOL_COMMAND_TYPE.DeviceReset,
      },
    });
  }, [mosiCharacteristic]);

  useEffect(() => {
    console.log('test2');
    if (csmCommands.length === 0) {
      console.log('csm ble dispatcher: no commands to send');
      return;
    }
    if (csmBleDevice?.gatt?.connected === false) {
      console.log('csm ble dispatcher: device is not connected. will not send commands');
      return;
    }
    if (mosiCharacteristic === null || pumpCharacteristic === null) {
      console.log('csm ble dispatcher: mosi or pump characteristic is null. will not send commands');
      return;
    }
    let t = Date.now();
    console.log('acquiring mutex to send command..');
    sendCommandMutexRef.current.acquire().then((release) => {
      console.log('command mutex acquired after ', Date.now() - t, 'ms');
      for (let cmd of csmCommands) {
        console.log('csm ble dispatcher: sending command', cmd.message.CmdType);
        try {
          if (cmd.message.CmdType === CSM_PROTOCOL_COMMAND_TYPE.SetPumpPower) {
            console.log('sleeping for 200ms');
            setTimeout(() => {
              let payload = cmd.message.Payload;
              if (!payload || payload.byteLength === 0) {
                throw new Error('error sending pump command, target value is empty');
              }
              console.log('sending pump power', payload);
              // console.log('writing to mosi characteristic', frame)
              csmPumpWriteToCharacteristic(pumpCharacteristic, payload).finally(() => {
                consumeCSMCommand(cmd.id);
                console.log('releasing command mutex');
                release();
              });
            }, 200);
          } else {
            let frame = encodeMosiCommand(cmd.message.CmdType, cmd.message.Payload);
            console.log('sleeping for 200ms');
            setTimeout(() => {
              // console.log('writing to mosi characteristic', frame)
              csmMosiWriteToCharacteristic(mosiCharacteristic, frame).finally(() => {
                consumeCSMCommand(cmd.id);
                console.log('releasing command mutex');
                release();
              });
            }, 200);
          }
        } catch (e: any) {
          console.log('error writing to mosi characteristic', e.message);
        }
        break;
      }
    });
  }, [csmCommands, mosiCharacteristic, pumpCharacteristic, csmBleDevice]);

  useEffect(() => {
    console.log('test3');

    if (csmBleDevice === undefined) {
      return;
    }
    getBleService(csmBleDevice)
      .then((service) => {
        // Important! Two notification characteristics on a single device causes unknown GATTT error on Androind
        // getHeartbeatCharacteristic(service)
        // .then(_heartbeatCharacteristic => {
        //     console.log('got heartbeat characteristic', _heartbeatCharacteristic)
        //     setHeartbeatCharacteristic(_heartbeatCharacteristic)
        // });
        getMisoCharacteristic(service).then((_misoCharacteristic) => {
          console.log('got miso characteristic', _misoCharacteristic);
          setMisoCharacteristic(_misoCharacteristic);
        });
        getMosiCharacteristic(service).then((_mosiCharacteristic) => {
          console.log('got mosi characteristic', _mosiCharacteristic);
          setMosiCharacteristic(_mosiCharacteristic);
        });
        getPumpCharacteristic(service).then((_pumpCharacteristic) => {
          console.log('got pump characteristic', _pumpCharacteristic);
          setPumpCharacteristic(_pumpCharacteristic);
        });
      })
      .catch((e: any) => {
        console.log('error subscribing device', e);
      });
  }, [csmBleDevice]);

  useEffect(() => {
    const onBeforeUnload = async (event: any) => {
      if (csmBleDevice !== undefined && csmBleDevice.gatt !== undefined && csmBleDevice.gatt.connected) {
        console.log('on beforeunload: disconnecting csm ble device');
        csmBleDevice.gatt.disconnect();
      }
      let confirmationValue = 'Quitting the page will disconnect your device from Neose BLE. Proceed?';
      event.returnValue = confirmationValue;
      return confirmationValue;
    };
    console.log('adding event listener for beforeunload');
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      console.log('removing event listener for beforeunload');
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (!navigatorSupportsWebBle()) {
      return;
    }
    var mutex = true;
    let i = setInterval(async () => {
      if (!mutex) {
        console.debug('refkit port detection: port detection mutex is false - skipping');
        return;
      }
      if (csmBleDevice !== undefined && csmBleDevice.gatt !== undefined && csmBleDevice.gatt.connected) {
        return;
      }
      console.debug('setting csm ble detection mutex to false');
      mutex = false;
      setCSMBleDevice(undefined);
      setCSMIsConnected(false);
      try {
        let device: BluetoothDevice | null = null;
        let deviceCandidates = await getBleDeviceCandidates();
        console.log('got ble devices', deviceCandidates);
        for (let _device of deviceCandidates) {
          try {
            let _service = await getBleService(_device);
            console.log('got ble service', _service);
            device = _device;
            break;
          } catch (e: any) {
            console.log('error getting ble service', e.message);
          }
        }
        if (device === null) {
          console.debug('no csm ble device gotten nor found');
          mutex = true;
          return;
        }
        // forget all the other devices
        for (let _device of deviceCandidates) {
          if (_device.id !== device.id) {
            console.log('forgetting unused device', _device);
            void (await _device.forget());
          }
        }
        setCSMBleDevice(device);
        setCSMIsConnected(true);
        console.debug('csm ble device found', device);
      } catch (e: any) {
        console.debug('no csm ble device found', e.message);
      }
      console.debug('setting csm ble detection mutex to true');
      mutex = true;
      return;
    }, DEFAULT_DEVICE_DETECTION_INTERVAL);
    return () => {
      console.debug('clearing interval for csm ble device detection');
      clearInterval(i);
      console.debug('setting csm ble device detection mutex to true');
      mutex = true;
      return;
    };
  }, [csmBleDevice]);

  return <ConnectionIndicator name="CSM over BLE" connectPath="/connect/ble" isConnected={csmIsConnected} minimized={minimized} queueLength={csmMessages.length} state={csmState} />;
};
