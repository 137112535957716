import { Typography, Row, Col, Select, Button, Alert, Popconfirm, message as antdMessage, Switch, Empty } from 'antd';
import { FC, useState, useRef, useEffect } from 'react';
import { FlexRow, Paper } from '../../common/common';
import { useMessageContext } from '../../../state/context/MessageContext';
import { colorHexToRGBA, ARYBALLE_COLOR_YELLOW, extendSensor, shouldExtendSpotsgrid } from '../../../utils/helpers/utils';
import { saveSdsSerial, saveSpotsGrid1D } from '../../../services/cache/localStorage';
import { ArrowRightOutlined, ReloadOutlined } from '@ant-design/icons';
import { fetchSpotfile, parseSpotsgrid2dFromSpotfile, spotsgrid2DTo1D, fetchSpotfileList, getSpotsgridTable, spotsgrids1dAreEqual } from './spotfile';
import { CSM_PROTOCOL_COMMAND_TYPE, CSM_PROTOCOL_EVENT_TYPE, CSM_PROTOCOL_LIFECYCLE_EVENT_STATE, encodeMosiSetBiosensorCommandData, parseBiosensorSignalMapResponse, parseEventPayload, parseResponsePayload } from '../../../components/serial/csm';
import { v4 as uuidv4 } from 'uuid';

export const BleSpotfileManagementWidget: FC = () => {
  const [sdsSerialValue, setSdsSerialValue] = useState<string | null>(null);
  const [spotfileNames, setSpotfileNames] = useState<string[]>([]);

  const [initialSpotsgrid1d, setInitialSpotsgrid1d] = useState<number[] | null>(null);
  const [currentSpotsgrid1d, setCurrentSpotsgrid1d] = useState<number[] | null>(null);

  const [shouldUseColors, setShouldUseColors] = useState<boolean>(true);

  const divRef = useRef<HTMLDivElement>(null);

  const { csmMessages, addCSMCommand, consumeCSMMessage, resetCSMstate } = useMessageContext();

  const fetchAndSetSpotfile = async () => {
    console.log('fetching spotfile', sdsSerialValue);
    if (!sdsSerialValue) {
      return;
    }
    const spotfile = await fetchSpotfile(sdsSerialValue);
    if (!spotfile) {
      throw new Error('error fetching spotfile from cloud');
    }
    const spotsgrid2d = parseSpotsgrid2dFromSpotfile(spotfile);
    if (!spotsgrid2d) {
      throw new Error('error parsing spotsgrid from CSV file');
    }
    console.log('spotsgrid 2d', spotsgrid2d);
    if (spotsgrid2d.length !== 16 || spotsgrid2d[0].length !== 4) {
      throw new Error('spotsgrid 2d is not 16x4');
    }
    let spotsgrid1d = spotsgrid2DTo1D(spotsgrid2d);
    setCurrentSpotsgrid1d(spotsgrid1d);
  };

  useEffect(() => {
    console.log('spotfile widget: mounting', window.location.hash);
    setTimeout(() => {
      if (divRef.current && window.location.hash === '#spotfile') {
        window.scrollTo({
          top: divRef.current.getBoundingClientRect().top - 60,
          behavior: 'smooth',
        });
        divRef.current.style.backgroundColor = colorHexToRGBA(ARYBALLE_COLOR_YELLOW, 1);
        divRef.current.style.padding = '10px';
        setTimeout(() => {
          divRef.current!.style.backgroundColor = 'transparent';
          divRef.current!.style.padding = '0px';
          // window.location.hash = ''
        }, 3000);
      }
    }, 100);
  }, [divRef.current]);

  useEffect(() => {
    console.log(0);
    handleGetBiosensorCSMCommand();
  }, []);

  useEffect(() => {
    fetchSpotfileList()
      .then((spotfileList: string[]) => {
        setSpotfileNames(spotfileList);
      })
      .catch((e: any) => {
        console.log('sds swap: fetch spotfile list error', e);
      });
  }, []);

  useEffect(() => {
    for (let message of csmMessages) {
      switch (message.message.Type) {
        case CSM_PROTOCOL_COMMAND_TYPE.GetBiosensorSignalMap:
          consumeCSMMessage(message.id);
          console.log(1);
          let biosensorMapResponsePayload = parseResponsePayload(message.message.Payload);
          let _spotsgrid1d = parseBiosensorSignalMapResponse(biosensorMapResponsePayload.Data);

          // extend only in the case of detected POR1/2/3 as it is trimmed when reading getBiosensorMap response
          let _spotsgrid1dSuffixed = shouldExtendSpotsgrid(_spotsgrid1d) ? _spotsgrid1d.map((spot: number) => extendSensor(spot)) : _spotsgrid1d;

          if (_spotsgrid1d) {
            console.log(2);

            saveSpotsGrid1D(_spotsgrid1d);
            setInitialSpotsgrid1d(_spotsgrid1dSuffixed);
            if (!currentSpotsgrid1d) {
              setCurrentSpotsgrid1d(_spotsgrid1dSuffixed);
            }
          }
          break;
        case CSM_PROTOCOL_COMMAND_TYPE.GetBiosensorSerialNumber:
          consumeCSMMessage(message.id);
          console.log(3);

          let biosensorSerialNumberResponsePayload = parseResponsePayload(message.message.Payload);
          let _sdsSerialValue = new TextDecoder().decode(biosensorSerialNumberResponsePayload.Data);
          console.log(`spotfile widget: received CSM S/N: ${_sdsSerialValue}`);
          setSdsSerialValue(_sdsSerialValue);
          break;
        case CSM_PROTOCOL_EVENT_TYPE.LifeCycleEvent:
          consumeCSMMessage(message.id);
          let lcData = parseEventPayload(message.message.Payload);
          let lcState = lcData.Data[0];
          if (lcState === CSM_PROTOCOL_LIFECYCLE_EVENT_STATE.Ready || lcState === CSM_PROTOCOL_LIFECYCLE_EVENT_STATE.Acquiring) {
            console.log('spotfile widget: CSM is ready after SetBiosensorSerialNumberAndSignalMap');
            addCSMCommand({
              id: uuidv4().toString(),
              message: {
                CmdType: CSM_PROTOCOL_COMMAND_TYPE.GetBiosensorSignalMap,
              },
            });
          }
          // if neose send frame --> stop sampling
          if (lcState === CSM_PROTOCOL_LIFECYCLE_EVENT_STATE.Acquiring) {
            // stop sampling
            addCSMCommand({
              id: uuidv4().toString(),
              message: {
                CmdType: CSM_PROTOCOL_COMMAND_TYPE.StopSampling,
              },
            });
          }
          break;
      }
    }
  }, [csmMessages]);

  const handleGetBiosensorCSMCommand = async () => {
    addCSMCommand({
      id: uuidv4().toString(),
      message: {
        CmdType: CSM_PROTOCOL_COMMAND_TYPE.GetBiosensorSignalMap,
      },
    });
    addCSMCommand({
      id: uuidv4().toString(),
      message: {
        CmdType: CSM_PROTOCOL_COMMAND_TYPE.GetBiosensorSerialNumber,
      },
    });
  };

  const handleClickReloadButton = async () => {
    setCurrentSpotsgrid1d(null);
    setInitialSpotsgrid1d(null);
    handleGetBiosensorCSMCommand();
  };

  const handleClickLoadButton = async () => {
    if (!sdsSerialValue) {
      antdMessage.error(`Please select an SDS S/N`);
      return;
    }
    try {
      await fetchAndSetSpotfile();
      saveSdsSerial(sdsSerialValue);
      antdMessage.success(`Loaded SDS spotfile: ${sdsSerialValue}`);
    } catch (e: any) {
      console.log('sds swap: error', e);
      antdMessage.error(`Error swapping SDS: ${e.message}`);
    }
  };

  const handleClickFlashButton = async () => {
    if (!sdsSerialValue) {
      antdMessage.error(`SDS SerialNumber is empty: please select an SDS S/N`);
      return;
    }
    if (!currentSpotsgrid1d) {
      antdMessage.error(`Spotfile is empty: please fill in the spotfile`);
      return;
    }
    let data = encodeMosiSetBiosensorCommandData(sdsSerialValue, currentSpotsgrid1d);
    console.log('spotfile mgmt widget: sending set biosensor command', data);
    resetCSMstate();
    void addCSMCommand({
      id: uuidv4().toString(),
      message: {
        CmdType: CSM_PROTOCOL_COMMAND_TYPE.SetBiosensorSerialNumberAndSignalMap,
        Payload: data,
      },
    });
  };

  return (
    <div ref={divRef} style={{ borderRadius: 10, marginTop: 20 }}>
      <Paper>
        <FlexRow style={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography.Title level={3}>Spotfile</Typography.Title>
          <Button icon={<ReloadOutlined />} onClick={handleClickReloadButton} />
        </FlexRow>

        <Row gutter={[10, 10]}>
          {/* spotgridTable or Empty */}
          {currentSpotsgrid1d ? (
            <>
              <Col xs={24}>
                <FlexRow style={{ alignItems: 'center' }}>
                  Use peptide color scheme:
                  <Switch size="small" checked={shouldUseColors} onChange={(checked: boolean) => setShouldUseColors(checked)} />
                </FlexRow>
              </Col>
              <Col xs={24} lg={12}>
                {getSpotsgridTable(currentSpotsgrid1d, shouldUseColors, (idx: number) => {
                  // Toggle spot by reversing its sign
                  let newSpotsgrid1d = [...currentSpotsgrid1d];
                  newSpotsgrid1d[idx] = -newSpotsgrid1d[idx];
                  // saveSpotsGrid1D(newSpotsgrid1d) // do not save on click, only on "save to cache" or on "flash"
                  console.log(`spotfile widget: toggled spot ${idx} to ${newSpotsgrid1d[idx]}`, newSpotsgrid1d);
                  setCurrentSpotsgrid1d(newSpotsgrid1d);
                })}
              </Col>
            </>
          ) : (
            <Col xs={24} lg={12}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <>
                    Spotfile is not set. Please prepare your <b>SDS S/N</b> and download the spotfile <b>using the section on the right</b> <ArrowRightOutlined />
                  </>
                }
              />
            </Col>
          )}

          <Col xs={24} lg={12} style={{ borderLeft: '1px solid #ccc', paddingLeft: 10 }}>
            <Row gutter={[5, 5]} justify="end">
              <Col xs={24}>Swap SDS</Col>
              <Col xs={24}>
                <Alert
                  type="info"
                  style={{ textAlign: 'left' }}
                  message={
                    <>
                      This sections allows you to update the spotfile in case you changed the SDS (cartridge). This functionality <b>requires internet connection</b>.
                    </>
                  }
                />
              </Col>
              <Col xs={24}>
                <Select
                  style={{ width: '100%' }}
                  placeholder="Type an SDS S/N and hit 'load'"
                  showSearch
                  value={sdsSerialValue}
                  options={spotfileNames.map((spotfileName: string) => {
                    return {
                      value: spotfileName,
                      label: spotfileName,
                    };
                  })}
                  onChange={(value: string) => setSdsSerialValue(value)}
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                />
              </Col>
              <Col xs={12}>
                <Button block onClick={handleClickLoadButton}>
                  Load
                </Button>
              </Col>
              <Col xs={12}>
                <Button block danger onClick={handleGetBiosensorCSMCommand}>
                  Revert
                </Button>
              </Col>

              {/* Flash button */}
              <Col xs={24}>
                {currentSpotsgrid1d && initialSpotsgrid1d && !spotsgrids1dAreEqual(currentSpotsgrid1d, initialSpotsgrid1d) && (
                  <Alert
                    type="warning"
                    style={{ textAlign: 'left' }}
                    message={
                      <>
                        <b>Warning:</b> spotfile is pending changes!
                        <br />
                        <br />
                        <b>Flash</b> them (save on the device) or revert to the initial state.
                        <br />
                        <b>Otherwise</b>, the changes will be <b>lost</b>.
                      </>
                    }
                    action={
                      <Popconfirm title="Ary you sure you want to flash the device with this spotfile?" onConfirm={handleClickFlashButton}>
                        <Button type="primary">Flash</Button>
                      </Popconfirm>
                    }
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Paper>
    </div>
  );
};
